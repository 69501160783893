.feedback-section .no-feedback {
  min-height: 100px;
}
.feedback-section .new-feedback-btn {
  gap: 5px;
}

.feedback-summary-section .rating-summary-numbers {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-left: 1px solid var(--gray-100);
}
.feedback-summary-section .rating-summary-numbers .average-rating {
  font-size: 2rem;
}
.feedback-summary-section .rating-summary-numbers .feedback-count {
  margin-top: -0.5rem;
}
.feedback-summary-section .rating-progress-bar-section {
  padding-bottom: 2rem;
}
.feedback-summary-section .rating-progress-bar-section .rating-bar-title {
  margin-right: -15px;
}
.feedback-summary-section .rating-progress-bar-section .rating-progress-bar {
  margin-bottom: 4px;
  height: 7px;
  margin-top: 6px;
}
.feedback-summary-section .rating-progress-bar-section .progress-bar-cosmetic {
  background-color: var(--gray-600);
  border-radius: var(--border-radius);
}
.feedback-summary-section .ratings-pill {
  background-color: var(--gray-100);
  padding: 0.5rem 1rem;
  border-radius: 66px;
}

.feedback-history .feedback-content {
  border-radius: var(--border-radius);
  border: 1px solid var(--border-color);
}
.feedback-history .feedback-content:last-child {
  border-bottom: 1px solid var(--border-color);
}

.circular-progress {
  width: 80px;
  height: 80px;
  line-height: 80px;
  position: relative;
}

.circular-progress:after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 7px solid var(--gray-200);
  position: absolute;
  top: 0;
  right: 0;
}

.circular-progress > span {
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 1;
}

.circular-progress .progress-left {
  right: 0;
}

.circular-progress .progress-bar {
  width: 100%;
  height: 100%;
  background: none;
  border-width: 6px;
  border-style: solid;
  position: absolute;
  top: 0;
}

.circular-progress .progress-left .progress-bar {
  right: 100%;
  border-top-left-radius: 80px;
  border-bottom-left-radius: 80px;
  border-right: 0;
  transform-origin: center right;
}

.circular-progress .progress-right {
  left: 0;
}

.circular-progress .progress-right .progress-bar {
  right: -100%;
  border-top-right-radius: 80px;
  border-bottom-right-radius: 80px;
  border-left: 0;
  transform-origin: center left;
  animation: loading-1 0.8s linear forwards;
}

.circular-progress .progress-value {
  width: 100%;
  height: 100%;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  position: absolute;
}

.circular-progress .progress-left .progress-bar {
  animation: loading-2 0.5s linear forwards 0.8s;
}

@keyframes loading-1 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(var(--deg-right));
  }
}
@keyframes loading-2 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(var(--deg-left));
  }
}
.node-card {
  background: white;
  border-radius: 0.5rem;
  padding: 0.75rem;
  margin-right: 3rem;
  width: 18rem;
  overflow: hidden;
}
.node-card .btn-edit-node {
  display: none;
}
.node-card .edit-chart-node {
  display: none;
}
.node-card .node-edit-icon {
  display: none;
}

.node-card.exported {
  box-shadow: none;
}

.node-image {
  width: 3rem;
  height: 3rem;
}

.node-name {
  font-size: var(--text-lg);
  color: var(--text-color);
  line-height: 1.72;
}

.node-title {
  font-size: 0.75rem;
  line-height: 1.35;
}

.node-info {
  width: 12.7rem;
}

.node-connections {
  font-size: 0.75rem;
  line-height: 1.35;
}

.node-card.active {
  background: var(--gray-100);
  border: 1px solid var(--gray-600);
  box-shadow: var(--shadow-md);
  border-radius: 0.5rem;
  padding: 0.75rem;
  width: 18rem;
}
.node-card.active .btn-edit-node {
  display: flex;
  background: var(--gray-300);
  color: var(--gray-800);
  font-size: 0.75rem;
  align-items: center;
  justify-content: center;
  box-shadow: var(--shadow-sm);
  gap: 6px;
}
.node-card.active .edit-chart-node {
  display: block;
}
.node-card.active .node-edit-icon {
  display: block;
}
.node-card.active .node-edit-icon > .icon {
  margin-top: -3px;
}
.node-card.active .node-name {
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2px;
  width: 12.2rem;
}

.node-card.active-path {
  background: var(--gray-100);
  border: 1px solid var(--gray-300);
  box-shadow: var(--shadow-sm);
  border-radius: 0.5rem;
  padding: 0.75rem;
  width: 15rem;
  height: 3rem;
}
.node-card.active-path .btn-edit-node {
  display: none !important;
}
.node-card.active-path .edit-chart-node {
  display: none;
}
.node-card.active-path .node-edit-icon {
  display: none;
}
.node-card.active-path .node-info {
  display: none;
}
.node-card.active-path .node-title {
  display: none;
}
.node-card.active-path .node-connections {
  display: none;
}
.node-card.active-path .node-name {
  font-size: 0.85rem;
  line-height: 1.35;
}
.node-card.active-path .node-image {
  width: 1.5rem;
  height: 1.5rem;
}
.node-card.active-path .node-meta {
  align-items: baseline;
}

.node-card.collapsed {
  background: white;
  border-radius: 0.5rem;
  padding: 0.75rem;
  width: 15rem;
  height: 3rem;
}
.node-card.collapsed .btn-edit-node {
  display: none !important;
}
.node-card.collapsed .edit-chart-node {
  display: none;
}
.node-card.collapsed .node-edit-icon {
  display: none;
}
.node-card.collapsed .node-info {
  display: none;
}
.node-card.collapsed .node-title {
  display: none;
}
.node-card.collapsed .node-connections {
  display: none;
}
.node-card.collapsed .node-name {
  font-size: 0.85rem;
  line-height: 1.35;
}
.node-card.collapsed .node-image {
  width: 1.5rem;
  height: 1.5rem;
}
.node-card.collapsed .node-meta {
  align-items: baseline;
}

#hierarchy-chart-wrapper {
  padding-top: 30px;
}
#hierarchy-chart-wrapper #arrows {
  margin-top: -80px;
}

.hierarchy {
  display: flex;
}

.hierarchy li {
  list-style-type: none;
}

.child-node {
  margin: 0px 0px 16px 0px;
}

.hierarchy .level, .hierarchy-mobile .level {
  margin-left: 8px;
  align-items: flex-start;
  flex-direction: column;
}

#arrows {
  position: absolute;
  overflow: visible;
}

.active-connector {
  stroke: var(--gray-600);
}

.collapsed-connector {
  stroke: var(--gray-400);
}

.hierarchy-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
  padding-right: 0px;
}

.hierarchy-mobile li {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.mobile-node {
  margin-right: 0;
}

.mobile-node.active-path {
  width: 12.25rem;
}

.active-child {
  width: 15.5rem;
}

.mobile-node .node-connections {
  max-width: 80px;
}

.hierarchy-mobile .node-children {
  margin-top: 16px;
}

.root-level .node-card {
  margin: 0 0 16px;
}

.collapsed-level {
  margin-bottom: 16px;
  width: 18rem;
}

.node-group {
  background: white;
  border: 1px solid var(--border-color);
  box-shadow: var(--shadow-sm);
  border-radius: 0.5rem;
  padding: 0.75rem;
  width: 18rem;
  height: 3rem;
  overflow: hidden;
  align-items: center;
}

.node-group .avatar-group {
  margin-right: 0px;
}

.node-group .avatar-extra-count {
  background-color: var(--gray-100);
  color: var(--gray-500);
}

.node-group .avatar-frame {
  width: 1.5rem;
  height: 1.5rem;
}

.node-group.collapsed {
  width: 5rem;
  margin-right: 12px;
}

.sibling-group {
  display: flex;
  flex-direction: column;
  align-items: center;
}